import React from 'react'
import BackedByMedical from './BackedByMedical'
import { graphql, useStaticQuery } from 'gatsby'

const SunlifeCovidBackedByMedical = () => {
  const icons = useStaticQuery(iconsQuery)

  const coachesContent = [
    {
      color: 'grapefruit',
      title: 'LONG COVID PHYSICIANS',
      imageClass: 'w-70 md:w-7/12',
      imageSrcDesktop: icons.longCovidPhysicians.publicURL,
      imageAltDesktop: icons.longCovidPhysicians.name,
      imageSrcMobile: icons.longCovidPhysiciansMobile.publicURL,
      imageAltMobile: icons.longCovidPhysiciansMobile.name
    },
    {
      color: 'larimar',
      title: 'PAIN & MIND-BODY PHYSICIANS & PHDS',
      imageClass: 'w-70 md:w-7/12',
      imageSrcDesktop: icons.painPhysicians.publicURL,
      imageAltDesktop: icons.painPhysicians.name,
      imageSrcMobile: icons.painPhysiciansMobile.publicURL,
      imageAltMobile: icons.painPhysiciansMobile.name
    },
    {
      color: 'slate',
      title: 'RESEARCH & CLINICAL DEVELOPMENT PHYSICIANS',
      imageClass: 'w-70 md:w-7/12',
      imageSrcDesktop: icons.clinicalDeveloipmentPhysicians.publicURL,
      imageAltDesktop: icons.clinicalDeveloipmentPhysicians.name,
      imageSrcMobile: icons.clinicalDeveloipmentPhysiciansMobile.publicURL,
      imageAltMobile: icons.clinicalDeveloipmentPhysiciansMobile.name
    },
    {
      color: 'honey',
      title: 'NUTRITION, BEHAVIORAL HEALTH, & PHYSICAL THERAPY EXPERTS',
      imageClass: 'w-70 md:w-10/12',
      imageSrcDesktop: icons.nutritionPhysicians.publicURL,
      imageAltDesktop: icons.nutritionPhysicians.name,
      imageSrcMobile: icons.nutritionPhysiciansMobile.publicURL,
      imageAltMobile: icons.nutritionPhysiciansMobile.name
    }
  ]

  return <BackedByMedical coachesContent={coachesContent} />
}

export default SunlifeCovidBackedByMedical

const iconsQuery = graphql`
  query iconsCovidBackedByMedicalQuery {
    longCovidPhysicians: file(
      relativePath: { eq: "employer/backedbymedical/longCovidPhysicians.png" }
    ) {
      name
      publicURL
    }
    longCovidPhysiciansMobile: file(
      relativePath: {
        eq: "employer/backedbymedical/longCovidPhysicians-mobile.png"
      }
    ) {
      name
      publicURL
    }
    painPhysicians: file(
      relativePath: { eq: "employer/backedbymedical/painPhysicians.png" }
    ) {
      name
      publicURL
    }
    painPhysiciansMobile: file(
      relativePath: { eq: "employer/backedbymedical/painPhysicians-mobile.png" }
    ) {
      name
      publicURL
    }
    clinicalDeveloipmentPhysicians: file(
      relativePath: {
        eq: "employer/backedbymedical/clinicalDeveloipmentPhysicians.png"
      }
    ) {
      name
      publicURL
    }
    clinicalDeveloipmentPhysiciansMobile: file(
      relativePath: {
        eq: "employer/backedbymedical/clinicalDeveloipmentPhysicians-mobile.png"
      }
    ) {
      name
      publicURL
    }
    nutritionPhysicians: file(
      relativePath: { eq: "employer/backedbymedical/nutritionPhysicians.png" }
    ) {
      name
      publicURL
    }
    nutritionPhysiciansMobile: file(
      relativePath: {
        eq: "employer/backedbymedical/nutritionPhysicians-mobile.png"
      }
    ) {
      name
      publicURL
    }
  }
`
