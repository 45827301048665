import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import MemberOutcomes from './MemberOutcomes'

const SunlifeCovidMemberOutcomes = () => {
  const icons = useStaticQuery(iconsQuery)

  const items = [
    {
      color: 'grapefruit',
      imageURL: icons.memberTamara.publicURL,
      imageName: icons.memberTamara.name,
      memberName: 'Tamara',
      memberContent: ['35 years old', 'Severe brain fog and fatigue'],
      before: [
        'Struggled with planning and focusing',
        'Had trouble falling and staying asleep',
        'Experienced some muscle pain'
      ],
      after: [
        'Paces her day and completes more of day-to-day tasks',
        'Sleeping as she did pre-COVID',
        'Using mindful walking to resume activity'
      ]
    },
    {
      color: 'slate',
      imageURL: icons.memberChristian.publicURL,
      imageName: icons.memberChristian.name,
      memberName: 'Christian',
      memberContent: ['50 years old', 'Shortness of breath, fatigue'],
      before: [
        'Lightheaded after mild activity',
        'Unable to exercise',
        'Anxious about impact of condition'
      ],
      after: [
        'Able to walk easily',
        'Considering resuming running habit',
        'Anxiety in normal ranges'
      ]
    },
    {
      color: 'larimar',
      imageURL: icons.memberAllison.publicURL,
      imageName: icons.memberAllison.name,
      memberName: 'Allison',
      memberContent: [
        '60 years old',
        'Shortness of breath, Extreme fatigue & memory problems'
      ],
      before: [
        'Trouble performing day-to-day activities',
        'Mild headaches',
        'Trouble staying asleep'
      ],
      after: [
        'Resuming more of her usual day-to-day activities',
        'Sleeping normally',
        'Re-engaging with social and professional life'
      ]
    }
  ]

  return <MemberOutcomes items={items} />
}

export default SunlifeCovidMemberOutcomes

const iconsQuery = graphql`
  query iconsSunlifeCovidMemberOutcomesQuery {
    memberTamara: file(
      relativePath: { eq: "employer/memberOutcomes/memberTamara.png" }
    ) {
      name
      publicURL
    }
    memberChristian: file(
      relativePath: { eq: "employer/memberOutcomes/memberChristian.png" }
    ) {
      name
      publicURL
    }
    memberAllison: file(
      relativePath: { eq: "employer/memberOutcomes/memberAllison.png" }
    ) {
      name
      publicURL
    }
  }
`
