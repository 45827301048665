import React from 'react'
import FAQ from './FAQ'

const SunlifeCovidFaq = () => {
  const faqsData = [
    {
      id: 1,
      readableId: '1',
      pageTitle: 'What is Goodpath?',
      content:
        'Goodpath is the whole-person, integrative clinic that address common, chronic conditions. Its whole-person care examines habits, lifestyle, and even stress levels to provide remedies for those contributors.  Goodpath blends the expertise of its 20+ medical disciplines with technology and human intervention to improve the health of its members.'
    },
    {
      id: 2,
      readableId: '2',
      pageTitle: 'How does Goodpath work with my doctor?',
      content:
        'Goodpath complements the care you’re receiving from your doctor. Goodpath support is a helpful resource in the day-to-day progress toward recovery. You are encouraged to share your Goodpath care plan with your doctor(s). Your doctor leads your care. Goodpath is there for the moments in between.'
    },
    {
      id: 3,
      readableId: '3',
      pageTitle: 'Why is Sun Life providing this?',
      content:
        'Improving disability conditions is about more than addressing the symptom. Focusing on whole-person care can help lead to faster recovery, improved symptoms, and may reduce the odds of relapse. In lived terms, it means: “I can feel the joy of reading again!” - Goodpath participant, after receiving care for brain fog'
    },
    {
      id: 4,
      readableId: '4',
      pageTitle: 'How to get started?',
      content:
        'You start with a simple health assessment. After the assessment, you will be assigned a dedicated health coach who will explain the program and help you set goals for recovery. Care is accessed via the Goodpath smartphone app and with physical items that are shipped to your home, at no additional cost.'
    },
    {
      id: 5,
      readableId: '5',
      pageTitle: 'How long is the program?',
      content:
        'Most members engage with the program for about 12 weeks. You and your health coach will determine when the right time is for you to graduate from the program. You have the option to reengage with the Goodpath program for up to a year following your initial start date.'
    }
  ]
  return <FAQ faqs={faqsData} />
}

export default SunlifeCovidFaq
